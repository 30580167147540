export const colorsIncome = [
  "#aceb0e",
  "#124e5b",
  "#125b38",
  "#23b09b",
  "#415b12",
  "#125b2b",
  "#125b3e",
  "#1a8675",
  "#23b09b",
  "#1eb067",
  "#1eb036",
  "#52e29a",
  "#7ee9b3",
  "#1eb01e",
  "#1eb067",
  "#09c567",
  "#0f5934",
  "#17844d",
  "#04592f",
  "#045904",
  "#068a49",
  "#08bb63",
  "#20770b",
  "#2da60f",
  "#3ad513",
  "#2da60f",
  "#1f8705",
  "#608705",
  "#207a3b",
  "#5ed582",
]

export const colorsOutcome = [
  "#dfa919",
  "#f8b400",
  "#926a00",
  "#c58f00",
  "#f8b400",
  "#ffc52c",
  "#f8f200",
  "#8b8408",
  "#bbb20b",
  "#ebe00e",
  "#f3ea39",
  "#aba30c",
  "#dbd00f",
  "#f0e62d",
  "#99931e",
  "#ab7b0c",
  "#c0a30f",
  "#edc915",
  "#ffd503",
  "#dbed15",
  "#edc915",
  "#c4a714",
  "#96800f",
  "#c47b14",
  "#edd351",
  "#ffc700",
  "#f7ff00",
  "#ff8700",
  "#c99d02",
  "#c96b02",
]

export const colorsStorage = ["#23b09b", "#999999"]
export const colorsAnalitic = ["#aceb0e", ...colorsOutcome]
